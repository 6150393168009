/**
 * @author Philip Van Raalte
 * @date 2017-08-12.
 */
import _ from "lodash";

const NAMES_LIST = {
  FIRST: [
    "James",
    "John",
    "Robert",
    "Michael",
    "William",
    "David",
    "Richard",
    "Charles",
    "Joseph",
    "Thomas",
    "Christopher",
    "Daniel",
    "Paul",
    "Mark",
    "Donald",
    "George",
    "Kenneth",
    "Steven",
    "Edward",
    "Brian",
    "Ronald",
    "Anthony",
    "Kevin",
    "Jason",
    "Matthew",
    "Gary",
    "Timothy",
    "José",
    "Jeffrey",
    "Frank",
    "Scott",
    "Eric",
    "Stephen",
    "Andrew",
    "Raymond",
    "Gregory",
    "Joshua",
    "Walter",
    "Dennis",
    "Arthur",
    "Ryan",
    "Carl",
    "Roger",
    "Juan",
    "Samuel",
    "Benjamin",
    "Keith",
    "Nicholas",
    "Roy",
    "Louis",
    "Carlos",
    "Victor",
    "Craig",
    "Sean",
    "Jesse",
    "Leonard",
    "Curtis",
    "Earl",
    "Frederick",
    "Joel",
    "Edwin",
    "Alexander",
    "Theodore",
    "Floyd",
    "Lewis",
    "Wesley",
    "Gordon",
    "Maurice",
    "Ramon",
    "Leslie",
    "Edgar",
    "Claude",
    "Javier",
    "Everett",
    "Marshall",
    "Kirk",
    "Seth",
    "Elwood",
    "Thaddeus",
    "Donovan",
    "Sanford",
    "Brock",
    "Layne",
    "Linda",
    "Mary",
    "Jennifer",
    "Lisa",
    "Helen",
    "Laura",
    "Kim",
    "Shirley",
    "Amy",
    "Marie",
    "Alice",
    "Julie",
    "Jean",
    "Denise",
    "Jane",
    "Lori",
    "Wanda",
    "Grace",
    "Esther",
    "June",
    "Elsie",
    "Vivian",
    "Dana",
    "Agnes",
    "Vera",
    "Iris",
    "Alison",
    "Olivia",
    "Francis",
    "Pat",
    "Felix",
    "Leo",
    "Antoine",
    "Mathis",
    "Mila",
    "Sebastian",
    "Adrian",
    "Camilla",
    "Riccardo",
    "Viola",
    "Gabriel",
    "Nikolaj",
    "Peter",
    "Stefan",
    "Elias",
    "Nils",
    "Klara",
    "Ondrej",
    "Adela",
    "Mikolaj",
    "Igor",
    "Lena",
    "Nikola",
    "Milena",
    "Mohammed",
    "Yosef",
    "Leah",
    "Artem",
    "Maxim",
    "Gustav",
    "Markuss",
    "Emils",
    "Vladislav",
    "Vera",
    "Yana",
    "Veronika",
    "Ayaz",
    "Mehmet",
    "Emir",
    "Yigit",
    "Furkan",
    "Elif",
    "Eslem",
    "Haruto",
    "Yuto",
    "Sota",
    "Yuki",
    "Kota",
    "Takumi",
    "Ryota",
    "Itsuki",
    "Kosuke",
    "Aoi",
    "Rio",
    "Alikhan",
    "Alinur",
    "Miras",
    "Aisha",
    "Inkar",
    "Nathaniel",
    "Omar",
    "Karam",
    "Jackson",
    "Aiden",
    "Lucas",
    "Lukas",
    "Liam",
    "Noah",
    "Ethan",
    "Mason",
    "Caden",
    "Oliver",
    "Grayson",
    "Jacob",
    "Michael",
    "Benjamin",
    "Carter",
    "James",
    "Jayden",
    "James",
    "Logan",
    "Caleb",
    "Ryan",
    "Luke",
    "Daniel",
    "Jack",
    "William",
    "Owen",
    "Gabriel",
    "Matthew",
    "Connor",
    "Jayce",
    "Isaac",
    "Sebastian",
    "Henry",
    "Cameron",
    "Wyatt",
    " Dylan",
    "Nathan",
    "Nicholas",
    "Julian",
    "Eli",
    "Levi",
    "Isaiah",
    "Landon",
    "David",
    "Andrew",
    "Brayden",
    "John",
    "Lincoln",
    "Samuel",
    "Joseph",
    "Hunter",
    "Joshua",
    "Mateo",
    "Dominic",
    "Adam",
    "Leo",
    "Ian",
    "Josiah",
    "Anthony",
    "Colton",
    "Max",
    "Thomas",
    "Evan",
    "Nolan",
    "Aaron",
    "Carson",
    "Christopher",
    "Hudson",
    "Cooper",
    "Adrian",
    "Jonathan",
    "Jason",
    "Charlie",
    "Miles",
    "Jeremiah",
    "Gavin",
    "Asher",
    "Austin",
    "Ezra",
    "Chase",
    "Xavier",
    "Jordan",
    "Tristan",
    "Easton",
    "Zachary",
    "Parker",
    "Bryson",
    "Tyler",
    "Cora",
    "Damian",
    "Elliot",
    "Cole",
    "Kai",
    "Luna",
    "Brandon",
    "Roman",
    "Leonardo",
    "Sawyer",
    "Diego",
    "Ramon",
    "Thor",
    "Tobias",
    "Silas",
    "Ludvig",
    "Henrik",
    "Arnell",
    "Brann",
    "Delson",
    "Eylan",
    "Denzil",
    "Jeryl",
    "Kingsly",
    "Valik",
    "Arvin",
    "Nate",
    "Kamren",
    "Jullian",
    "Jules",
    "Gerrit",
    "Cyril",
    "Wilbur",
    "Vito",
    "Rufus",
    "Blayne",
    "Ólafur",
    "Jökull",
    "Jon",
    "Viktor",
    "Johann",
    "Arnau",
    "Felix",
    "Vojtech",
    "Kristóf",
    "Sándor",
    "János",
    "Krisztofer",
    "Gyula",
    "Kolos",
    "Dominik",
    "Khayone",
    "Nurasyl"
  ],
  LAST: [
    "Smith",
    "Johnson",
    "Williams",
    "Jones",
    "Brown",
    "Davis",
    "Miller",
    "Wilson",
    "Moore",
    "Taylor",
    "Anderson",
    "Thomas",
    "Jackson",
    "White",
    "Harris",
    "Martin",
    "Thompson",
    "Garcia",
    "Martinez",
    "Robinson",
    "Clark",
    "Rodriguez",
    "Lewis",
    "Lee",
    "Walker",
    "Hall",
    "Allen",
    "Young",
    "Hernandez",
    "King",
    "Wright",
    "Hill",
    "Green",
    "Adams",
    "Baker",
    "Mitchell",
    "Perez",
    "Roberts",
    "Turner",
    "Campbell",
    "Parker",
    "Evans",
    "Edwards",
    "Collins",
    "Stewart",
    "Sanchez",
    "Morris",
    "Reed",
    "Howard",
    "Cooper",
    "Watson",
    "Brooks",
    "Murphy",
    "Bryant",
    "Griffin",
    "Russell",
    "Diaz",
    "Powell",
    "Coleman",
    "Webb",
    "Simpson",
    "Hunter",
    "Knight",
    "Rose",
    "Shaw",
    "Burns",
    "Boyd",
    "Duncan",
    "Carroll",
    "Snyder",
    "Stone",
    "Stanley",
    "Hansen",
    "Oliver",
    "Frazier",
    "Brewer",
    "Larson",
    "Graves",
    "Sutton",
    "Walters",
    "Vargas",
    "Steele",
    "Schultz",
    "Haynes",
    "Rhodes",
    "Fletcher",
    "Holt",
    "Santos",
    "Garner",
    "Reeves",
    "Ingram",
    "Cohen",
    "Webster",
    "Kane",
    "Keith",
    "Hossa",
    "Seabrook",
    "Kruger",
    "Kero",
    "Abbott",
    "Crawford",
    "Stone",
    "Karlsson",
    "Ryan",
    "Kelly",
    "Wideman",
    "Jookipakka",
    "Robbinson",
    "MacArthur",
    "Varon",
    "Chabot",
    "Paul",
    "Anderson",
    "Hammond",
    "Canden",
    "Grabner",
    "Nash",
    "Miller",
    "Hayes",
    "Kreider",
    "Pavelec",
    "Oshie",
    "Eller",
    "Carlson",
    "Orlov",
    "Wyatt",
    "Addison",
    "Fischer",
    "Schneider",
    "Schmidt",
    "Hoffmann",
    "Huber",
    "Vogel",
    "Zakar",
    "Ziv",
    "Zylus",
    "Brahms",
    "Breken",
    "Cliffton",
    "Charles",
    "Frey",
    "Kawan",
    "Kiam",
    "Lenyx",
    "Darryn",
    "Jett",
    "Osman",
    "Tyren",
    "Voigt",
    "Winter",
    "Moreau",
    "Dubois",
    "Garcia",
    "Laurent",
    "Bernard",
    "Fournier",
    "Lefevre",
    "Andre",
    "Roussel",
    "Chevalier",
    "Nguyen",
    "Dufour",
    "Pierre",
    "Roche",
    "Picard",
    "Riviere",
    "Le Gall",
    "Le Goff",
    "Boucher",
    "Cordier",
    "Evrard",
    "Levy",
    "Tessier",
    "Vaillant",
    "Pineau",
    "Bakker",
    "Acker",
    "De Keyser",
    "De Graw",
    "Dane",
    "Dalebout",
    "De Waard",
    "Dier",
    "Dolsen",
    "Kanis",
    "Kalafut",
    "Loden",
    "Loewen",
    "Linderman",
    "Lore",
    "Mondt",
    "Miesen",
    "Ratte",
    "Rediger",
    "Ramus",
    "Steffen",
    "Sweere",
    "Struve",
    "Van Dale",
    "Van Buren",
    "Van Sandt",
    "Van Zyl",
    "Weide",
    "Baan",
    "Klein",
    "Wolff",
    "Rees",
    "Price",
    "Moss",
    "Driscoll",
    "Fleming",
    "Burns",
    "Millar",
    "Munro",
    "Graham"
  ]
};

export default function() {
  return `${NAMES_LIST.FIRST[_.random(0, NAMES_LIST.FIRST.length - 1)]} ${
    NAMES_LIST.LAST[_.random(0, NAMES_LIST.LAST.length - 1)]
  }`;
}
