/**
 * @author Philip Van Raalte
 * @date 2017-08-12.
 */

export default [
  "Toronto",
  "Montreal",
  "Vancouver",
  "Lachine",
  "Mississauga",
  "Leamington",
  "Camrose",
  "Richmond",
  "Markham",
  "Creston",
  "Coquitlam",
  "Victoria",
  "Port Coquitlam",
  "North Vancouver",
  "Burnaby",
  "Surrey",
  "Delta",
  "Vernon",
  "New Westminster",
  "Langley",
  "Kamloops",
  "Maple Ridge",
  "Abbotsford",
  "Chilliwack",
  "Whistler Village",
  "Kelowna",
  "Calgary",
  "Lethbridge",
  "Edmonton",
  "Lacombe",
  "Spruce Grove",
  "Sherwood Park",
  "Okotoks",
  "Strathmore",
  "Airdrie",
  "Leduc",
  "Grande Prairie",
  "Chatham",
  "Tavistock",
  "Perth",
  "Midland",
  "Laval",
  "Hamilton",
  "Ottawa",
  "Fergus",
  "Scarborough",
  "Cambridge",
  "Waterloo",
  "Welland",
  "Kitchener",
  "Longueuil",
  "Oshawa",
  "Brampton",
  "Rougemont",
  "Saint-Remi",
  "London",
  "Milton",
  "Pointe-Claire",
  "Sherbrooke",
  "Kanata",
  "Gatineau",
  "Lasalle",
  "Ingersoll",
  "Alma",
  "Hawkesbury",
  "Shannonville",
  "Elk Point",
  "Burgessville",
  "Brantford",
  "Woodstock",
  "Thornhill",
  "Stoney Creek",
  "Caledonia",
  "Burlington",
  "Grimsby",
  "Niagara Falls",
  "Smithville",
  "Jordan",
  "Peterborough",
  "Granby",
  "Cowansville",
  "Boisbriand",
  "Levis",
  "Sorel",
  "Blainville",
  "Mont-Royal",
  "Nominingue",
  "Terrebonne",
  "Saint-Leonard",
  "Westmount",
  "Kirkland",
  "Delson",
  "Saint-Sauveur",
  "Sabrevois",
  "Saint-Lambert",
  "Mascouche",
  "Saint-Jerome",
  "Chambly",
  "Varennes",
  "Mirabel",
  "Brossard",
  "Repentigny",
  "Sainte-Therese",
  "Dorval",
  "Leonard",
  "Verdun",
  "Saint-Agapit",
  "Boucherville",
  "Jonquiere",
  "Chicoutimi",
  "Brossard",
  "Candiac",
  "Kincardine",
  "North Perth",
  "Stratford",
  "Les Escoumins",
  "Saint-Jacques",
  "L'Assomption",
  "Waterville",
  "Amos",
  "La Morandiere",
  "Barraute",
  "Val-d'Or",
  "Orleans",
  "Etobicoke",
  "Guelph",
  "Whitby",
  "Pickering",
  "Vercheres",
  "Canning",
  "Wolfville",
  "Kentville",
  "Centreville",
  "Cambridge",
  "Ancaster",
  "Jerseyville",
  "North Russell",
  "Cote-Saint-Luc",
  "Mont-Tremblant",
  "Tecumseh",
  "Kingsville",
  "Amherstburg",
  "Newmarket",
  "Essex",
  "Harrow",
  "Wheatley",
  "Tecumseh",
  "Memphremagog",
  "Stettler",
  "Banff",
  "Canmore",
  "Didsbury",
  "Carstairs",
  "Blairmore",
  "Edson",
  "Lloydminster",
  "Vermilion",
  "Winnipeg",
  "Hinton",
  "Jasper",
  "Westlock",
  "Kimberley",
  "Cranbrook",
  "Marysville",
  "Fernie",
  "Canyon",
  "Golden",
  "Invermere",
  "Sorrento",
  "Williams Lake",
  "Grand Forks",
  "Greenwood",
  "Peachland",
  "Regina",
  "Rosetown",
  "Canora",
  "Kindersley",
  "La Ronge",
  "Humboldt",
  "Warman",
  "Melfort",
  "Meadow Lake",
  "Esterhazy",
  "Kamsack",
  "North Battleford",
  "Weyburn",
  "Maidstone",
  "Estevan",
  "Yorkton",
  "Melville",
  "Maple Creek",
  "Moosomin",
  "Steinbach",
  "Stonewall",
  "Selkirk",
  "Teulon",
  "Pine Falls",
  "Beausejour",
  "Thompson",
  "St. Paul",
  "Sechelt",
  "Mission",
  "Biggar",
  "Midale",
  "Nipawin",
  "Lampman",
  "Saskatoon",
  "Halifax",
  "Bridgewater",
  "Italy Cross",
  "Lunenburg",
  "Shelburne",
  "Mahone Bay",
  "Dartmouth",
  "Truro",
  "Springhill",
  "Halifax",
  "Sackville",
  "Mount Uniacke",
  "Beaver Bank",
  "Port Dover",
  "Canfield",
  "Dunnville",
  "Hagersville",
  "Waterford",
  "Cayuga",
  "Sidney",
  "Crofton",
  "Saanichton",
  "North Saanich",
  "Mayne",
  "Shawnigan Lake",
  "Duncan",
  "Chemainus",
  "Lake Cowichan",
  "Cobble Hill",
  "Ladysmith",
  "Powell River",
  "Van Anda",
  "Sault Ste. Marie",
  "Manitouwadge",
  "Wawa",
  "Thunder Bay",
  "Atikokan",
  "Terrace Bay",
  "Schreiber",
  "Woodbridge",
  "Mukilteo",
  "Fairfield",
  "Chicago",
  "Hernando",
  "Irving",
  "Baltimore",
  "Kingston",
  "Burlington",
  "Harrison",
  "Newton Center",
  "Littleton",
  "Bloomington",
  "San Ramon",
  "Downers Grove",
  "Circleville",
  "Oxnard",
  "Gulfport",
  "Portland",
  "Houston",
  "Greenbank",
  "Somerset",
  "Sidman",
  "Minneapolis",
  "Manchester",
  "Canton",
  "The Bronx",
  "San Francisco",
  "Saluda",
  "San Jose",
  "Santa Clara",
  "Sunnyvale",
  "Washington",
  "Harrisburg",
  "Stafford",
  "Newington",
  "Vienna",
  "Cambridge",
  "Boston",
  "South Harwich",
  "Worcester",
  "Tampa",
  "Lake Wales",
  "Saint Petersburg",
  "Kyle",
  "Pomona",
  "Covina",
  "Edmonds",
  "Kirkland",
  "White Plains",
  "Waynesboro",
  "Buffalo",
  "New York",
  "Ridgewood",
  "Utica",
  "Port Washington",
  "Brecksville",
  "Streetsboro",
  "Hartford",
  "Nashville",
  "Chester",
  "Richmond",
  "Jacksonville",
  "Kissimmee",
  "Clinton",
  "Denver",
  "Fort Worth",
  "Brandon",
  "Dover",
  "Nazareth",
  "Pine Brook",
  "Edison",
  "Redwood City",
  "Arvada",
  "Pineville",
  "Ponte Vedra Beach",
  "Sarasota",
  "Jackson",
  "Nacogdoches",
  "Tomball",
  "McKinney",
  "Little Rock",
  "Dallas",
  "Halethorpe",
  "Crofton",
  "Northbrook",
  "Palatine",
  "New Hyde Park",
  "Phoenix",
  "Northville",
  "St Louis",
  "Lafayette",
  "Castle Rock",
  "Windsor",
  "Laurel",
  "Collegeville",
  "Twinsburg",
  "Euless",
  "New Orleans",
  "Garland",
  "Mechanicsville",
  "Sterling",
  "Temple Hills",
  "Glen Allen",
  "Hopkins",
  "Jersey City",
  "Parsippany",
  "Wanaque",
  "Whippany",
  "Bothell",
  "Mill Valley",
  "Oakland",
  "Canonsburg",
  "Naperville",
  "Columbus",
  "Fairfax",
  "Frederick",
  "Dumfries",
  "Charlotte",
  "Bradenton",
  "Winchester",
  "Anniston",
  "Round Rock",
  "Humble",
  "Katy",
  "Golden",
  "Colorado Springs",
  "Merrick",
  "Northfield",
  "Ridgefield Park",
  "North Palm Beach",
  "Boca Raton",
  "High Bridge",
  "Piscataway",
  "Huntington Beach",
  "Tempe",
  "Yorba Linda",
  "Duarte",
  "Oceanside",
  "Redding",
  "Fountain Valley",
  "Clinton",
  "Durham",
  "Marysville",
  "Long Island City",
  "Haverford",
  "League City",
  "Jackson",
  "Berkley",
  "Plano",
  "Lexington",
  "Atlanta",
  "Brighton",
  "Wakefield",
  "East Falmouth",
  "Louisa",
  "Gaithersburg",
  "Ashland",
  "Columbia",
  "Upper Marlboro",
  "Oviedo",
  "Birmingham",
  "Tallahassee",
  "Tustin",
  "Suwanee",
  "Pompano Beach",
  "Grapevine",
  "Pittsburgh",
  "Butler",
  "South Jordan",
  "Elk Grove Village",
  "Milwaukee",
  "Bradford",
  "Villa Park",
  "Verona",
  "Elma",
  "Henderson",
  "Goldsboro",
  "Bennettsville",
  "Waynesboro",
  "Pembroke",
  "Shelby",
  "Wilmer",
  "Quitman",
  "Register",
  "Lawrenceville",
  "Muskegon",
  "West Mifflin",
  "North Rose",
  "Webster",
  "Cedar Springs",
  "Arlington Heights",
  "Elgin",
  "West Lafayette",
  "Justin",
  "Horn Lake",
  "Sedalia",
  "Roscommon",
  "Mt. Dora",
  "Albuquerque",
  "Sierra Vista",
  "Hamden",
  "Hackensack",
  "Sheridan",
  "Mosinee",
  "Lafayette",
  "Peoria",
  "Farmington Hills",
  "Kingsbury",
  "Muncie",
  "Charlestown",
  "Aurora",
  "New Richmond",
  "Stout",
  "Marshall",
  "Aurora",
  "Lyons",
  "Elizabeth",
  "Canton",
  "Akron",
  "Alliance",
  "Freeport",
  "Lincoln",
  "Montpelier",
  "Rozet",
  "Leeds",
  "Union Lake",
  "Dundee",
  "Capac",
  "Massillon",
  "Missouri City",
  "Buda",
  "Meridian",
  "Richmond Hill",
  "Interlachen",
  "Cranston",
  "Glendale",
  "Placitas",
  "Tucson",
  "Las Cruces",
  "El Paso",
  "Thoreau",
  "Tacoma",
  "Olympia",
  "Camdenton",
  "Kansas City",
  "Fredericktown",
  "Independence",
  "Greenwich",
  "Oklahoma City",
  "House Springs",
  "Barnhart",
  "Belleville",
  "Catawissa",
  "Woodstock",
  "Fresno",
  "Pahrump",
  "Grass Valley",
  "Cobb",
  "East Haddam",
  "Radford",
  "Cleveland",
  "Perth Amboy",
  "Irvington",
  "Vesuvius",
  "Amherst",
  "Dayton",
  "Spencerville",
  "Manchester",
  "Brunswick",
  "West Union",
  "Lima",
  "Madison",
  "Lexington",
  "Rochelle",
  "Aniwa",
  "Rushmore",
  "Genoa",
  "Greenville",
  "Monticello",
  "Aberdeen",
  "River Falls",
  "Eagle River",
  "Morrill",
  "Vancouver",
  "Fort Gibson",
  "Salem",
  "Wellford",
  "Anderson",
  "Greenville",
  "Clifton",
  "Nellysford",
  "Buffalo",
  "Hiawatha",
  "Elwood",
  "Sabetha",
  "Seneca",
  "Horton",
  "Ocala",
  "Sugar Grove",
  "West Jefferson",
  "Todd",
  "Banner Elk",
  "Crumpler",
  "Vilas",
  "Fleetwood",
  "Sparta",
  "Lansing",
  "Piney Creek",
  "Zionville",
  "Riverton",
  "Augusta",
  "Stockton",
  "Russell",
  "Great Bend",
  "Agra",
  "Norton",
  "Hays",
  "Lenora",
  "Versailles",
  "Tinley Park",
  "Lone Pine",
  "Studio City",
  "Oak Ridge",
  "Ellsworth",
  "Girvin",
  "King City",
  "Hugo",
  "Idabel",
  "Gruver",
  "Carrier",
  "Henryetta",
  "Hillister",
  "Chandler",
  "Bellevue",
  "Findlay",
  "Vinalhaven",
  "Monroe",
  "Burnham",
  "Enosburg Falls",
  "Fairlee",
  "Piffard",
  "Youngstown",
  "Ransomville",
  "Jefferson",
  "Canisteo",
  "Whitehall",
  "Hanapepe",
  "Kekaha",
  "Kealakekua",
  "Waimanalo",
  "Elysian",
  "Arlington",
  "Jasper",
  "Henderson",
  "Porter",
  "Minneota",
  "Hanley Falls",
  "Harshaw",
  "Rib Lake",
  "Latimer",
  "Gresham",
  "West Leyden",
  "Forestport",
  "Hampton",
  "Edgewood",
  "Brownstown",
  "Kinmundy",
  "Shumway",
  "Ramsey",
  "Sandoval",
  "Friol",
  "Bergondo",
  "Trives",
  "Caion",
  "Muro",
  "Laracha",
  "Garcia",
  "Arino",
  "Wethersfield",
  "Hindley",
  "Pelsall",
  "Darlaston",
  "Gnosall",
  "Boechout",
  "Rumst",
  "Reet",
  "Muizen",
  "Herenthout",
  "Berlaar",
  "Hulshout",
  "Rotselaar",
  "Putte",
  "Rygge",
  "Leirsund",
  "Dal",
  "Raholt",
  "Dilling",
  "Elverum",
  "Trondheim",
  "Kolsas",
  "Fornebu",
  "Salto",
  "Agulha",
  "Araras",
  "Bregare",
  "Byala Slatina",
  "Sofronievo",
  "Kula",
  "Lyulyakovo",
  "Kableshkovo",
  "Olympia",
  "Outapi",
  "Otjiwarongo",
  "Swakopmund",
  "Noble",
  "Algonquin",
  "Pampa",
  "Turpin",
  "Sunflower",
  "Galesville",
  "Chesterton",
  "Staten Island",
  "Englewood",
  "Madison",
  "Shelton",
  "Wallingford",
  "Chester",
  "South Windsor",
  "Colchester",
  "Groton",
  "Webster",
  "Friant",
  "Excelsior Springs",
  "Bristow",
  "Lawrence"
];
